import React, { useState } from "react";
import "./Login.css";
import { Button } from "@material-ui/core";
import db, { auth, provider, fbprovider } from "./firebase";
import { useStateValue } from "./StateProvider";
import { actionTypes } from "./reducer";
import { Link, useHistory } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function Login({ handleClose }) {
  const [state, dispatch] = useStateValue();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const signIn = () => {
    auth
      .signInWithPopup(provider)
      .then((result) => {
        console.log(result);
        handleClose();
        return db.collection("users").doc(result.user.uid).set({
          admin: false,
          displayName: result.user.displayName,
          photoURL: result.user.photoURL,
        });
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const signInFB = () => {
    auth
      .signInWithPopup(fbprovider)
      .then((result) => {
       // console.log(result);
        handleClose();
        return db.collection("users").doc(result.user.uid).set({
          admin: false,
          displayName: result.user.displayName,
          photoURL: result.user.photoURL,
        });
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayName, setDisplayName] = useState("");

  const login = (event) => {
    event.preventDefault();
    //login stuff
    auth
      .signInWithEmailAndPassword(email, password)
      .then((auth) => {
        //logged in redirect
        //history.push("/");
        handleClose();
      })
      .catch((e) => alert(e.message));
  };

  const register = (event) => {
    event.preventDefault();
    //register stuff
    auth
      .createUserWithEmailAndPassword(email, password)
      .then((auth) => {
        //created a user and logged in ...
        handleClose();
        //Send Verification Email.
        auth.user.sendEmailVerification({
          url: 'https://memewarsusa.com/',
        });
        return db.collection("users").doc(auth.user.uid).set({
          admin: false,
          displayName: displayName,
          photoURL: auth.user.photoURL,
        });
      })
      .catch((e) => alert(e.message));
  };

  return (
    <div className="login">
      <div className="login__container">
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            variant="fullWidth"
          >
            <Tab label="Sign In" />
            <Tab label="Register" />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <h2>Sign in to Meme Wars USA</h2>

          <div className="login__containerUser">
            <form>
              <h5>E-mail</h5>
              <input
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                type="text"
              />
              <h5>Password</h5>
              <input
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                type="password"
              />
              <Button onClick={login} className="login__signInButton">
                Sign In
              </Button>
            </form>
            {/* <Button onClick={register} className="login__registerButton">
              Create Your Account
            </Button> */}
             <Button onClick={signIn}>Sign In with Google</Button>
          <Button onClick={signInFB}>Sign In with Facebook</Button>
          </div>
         
        </TabPanel>
        <TabPanel value={value} index={1}>
        <h2>Register to Meme Wars USA</h2>
        <div className="login__containerUser">
            <form>
              <h5>E-mail</h5>
              <input
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                type="text"
              />
              <h5>Password</h5>
              <input
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                type="password"
              />
              <h5>User Name</h5>
              <input
                value={displayName}
                onChange={(event) => setDisplayName(event.target.value)}
                type="text"
              />
            </form>
            <Button onClick={register} className="login__registerButton">
              Create Your Account
            </Button>
          </div>
      </TabPanel>
      </div>
    </div>
  );
}

export default Login;
