import React, { useEffect } from 'react';
import './App.css';
import Header from './Header';
import Sidebar from './Sidebar';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom'
import Login from './Login';
import { useStateValue } from './StateProvider';
import { auth } from "./firebase"
import Memes from './Memes';
import RightSidebar from './RightSidebar';
import MemeDetails from './MemeDetails';
import VerifyEmail from './VerifyEmail';
import AccountManagement from './AccountManagement';
import { db } from './firebase';

function App() {
  const [{user, refreshUser}, dispatch] = useStateValue();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {

      let userRef = db.collection("users").doc(authUser?.uid);

        userRef.get().then(function(doc) {
            if (doc.exists) {
                //console.log("Document data:", doc.data());
                 //the users is looged in
                // authUser.displayName = doc.data().displayName;
                // authUser.photoURL = doc.data().photoURL;
               
                dispatch({
                  type: "SET_USER_DETAILS",
                  userDetails: doc.data(),
                });
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });

      if(authUser){
        dispatch({
          type: "SET_USER",
          user: authUser,
        });

      } else{
        //user is logged out
        dispatch({
          type: "SET_USER",
          user: null
        });
      }

    });

    return () => {
      //any cleanup operations go here.
      unsubscribe();
    }


  }, [refreshUser]);

  //console.log("user",user);

  //routes

  let routes;

  if(user){
    if(!user.emailVerified && user.providerData[0].providerId == "password"){
    routes = (
      <Switch>
      <Route path="/verifyEmail">
        <VerifyEmail />
      </Route>
      <Redirect to='/verifyEmail' />
    </Switch>
    );
    }
    else{
      routes = (
        <div className="app__body">
        <div className="app__container">
        {/* <Redirect to="/" /> */}
        <Switch>
          <Route exact path="/account">
          <Sidebar />
            <AccountManagement />
          </Route>
          <Route exact path="/meme/:memeId">
          <Sidebar />
            <MemeDetails/>
            <RightSidebar />
          </Route>
          <Route exact path="/Top Memes">
          <Sidebar orderBy='Top Memes' />
          <Memes orderBy='Top Memes'/>
          <RightSidebar />
          </Route>
          <Route exact path="/Random Memes">
          <Sidebar orderBy='Random Memes' />
          <Memes orderBy='Random Memes'/>
          <RightSidebar />
          </Route>
          <Route path="/">
          <Sidebar orderBy='Latest Memes' />
          <Memes orderBy='Latest Memes'/>
          <RightSidebar />
          </Route>
          </Switch>
          </div>
        </div>
      );
    }
  } else{
    routes = (
      <div className="app__body">
      <div className="app__container">
      <Switch>
        <Route path="/meme/:memeId">
        <Sidebar />
          <MemeDetails/>
        </Route>
        <Route path="/Top Memes">
        <Sidebar orderBy='Top Memes' />
        <Memes orderBy='Top Memes'/>
        </Route>
        <Route path="/Random Memes">
        <Sidebar orderBy='Random Memes' />
        <Memes orderBy='Random Memes'/>
        </Route>
        <Route path="/">
        <Sidebar orderBy='Latest Memes' />
        <Memes orderBy='Latest Memes'/>
        </Route>
        <Redirect to="/" />
        </Switch>
      <RightSidebar />
        </div>
      </div>
    );
  }

  return (
    <div className="app">
      <Router>
          <Header />
          {routes}
      </Router>
    </div>
  );
}

export default App;
