import React,{useState,useEffect} from "react";
import "./MemeContainer.css";
import ChatBubbleOutlineOutlinedIcon from "@material-ui/icons/ChatBubbleOutlineOutlined";
import ThumbUpAltOutlinedIcon from "@material-ui/icons/ThumbUpAltOutlined";
import ThumbDownAltOutlinedIcon from "@material-ui/icons/ThumbDownAltOutlined";
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Moment from 'react-moment';
import { useStateValue } from './StateProvider'
import {db, increment, decrement} from './firebase'
import {Link} from 'react-router-dom'
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import {FacebookShareButton} from "react-share"
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Snackbar from "@material-ui/core/Snackbar"

function MemeContainer({ memeId, title, username, timestamp, memeImage, memeDesc , memeLike, memeComments }) {
  const[{user}] = useStateValue();
  const [like, setLike] = useState(false);
  const [unlike, setUnlike] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [likeness, setLikeness] = useState(0);
  const [likeCount, setLikeCount] = useState(memeLike);
  const [commentCount, setCommentCount] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openCopied, setOpenCopied] = useState(false);
  

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if(user?.uid){
    getLikeStatus();
    }else{
      setLike(false);
      setUnlike(false);
    }
    //getLikeness();
    //getComments();
    //setLikeCount(memeLike);
  }, [refresh,user,memeId]);

  const getLikeStatus = () => {
    let likesRef = db.collection('likes');
    likesRef.where("memeId","==",memeId).where("userId","==",user.uid).limit(1)
    .get()
    .then(function(querySnapshot) {
      setLike(false);
      querySnapshot.forEach(function(doc) {
          // doc.data() is never undefined for query doc snapshots
          //console.log(doc.id, " => ", doc.data());
          if(doc.data().likeType == '1'){
          setLike(doc.data().like)
          }
          else if(doc.data().likeType == '2') {
          setUnlike(doc.data().like)
          }
      });
  })
  .catch(function(error) {
      console.log("Error getting documents: ", error);
  });

  //memeDetails
      let memeRef = db.collection('memes').doc(memeId);
      memeRef
      .get()
      .then(function(doc) {
        setLikeCount(doc.data().memeLike);
    })
    .catch(function(error) {
        console.log("Error getting documents: ", error);
    });


  }


  const likeMeme = () => {
    const likeId = memeId + user.uid;

    const likeMemeBatch = db.batch();
    const likesRef = db.collection('likes').doc(likeId);
    const memeRef = db.collection('memes').doc(memeId);

    likeMemeBatch.set(likesRef,{
      userId: user.uid,
      memeId: memeId,
      like: !like,
      likeType: 1,
    });
    if(!like){
      likeMemeBatch.set(memeRef, { memeLike:increment },{merge: true})
      //setLikeCount(likeCount + 1);
    }
    else{
      likeMemeBatch.set(memeRef, { memeLike:decrement },{merge: true})
      //setLikeCount(likeCount - 1);
    }
    likeMemeBatch.commit().then(function() {
          setUnlike(false)
          setRefresh(refresh + 1);
      })
      .catch(function(error) {
          console.error("Error writing document: ", error);
      });
  }

      //SHARE FUNCTION
      const [anchorElShare, setAnchorElShare] = useState(null);
      const [openShare, setOpenShare] = React.useState(false);
      const [placementShare, setPlacementShare] = React.useState();
    
      const handleClickShare = (event) => {
        const newPlacement = "top-end";
        console.log("hello");
        setAnchorElShare(event.currentTarget);
        setOpenShare((prev) => placementShare !== newPlacement || !prev);
        setPlacementShare(newPlacement);
      };
  
      const copied = () => {
        setOpenCopied(true);
      }

      const handleCloseCopied = () => {
        setOpenCopied(false);
      }

  const loginButtonOpen = () => {
    // setOpen(true);
  };  

  const [admin, setAdmin] = useState(false);


    useEffect(() => {

    if(user?.uid){
        db.collection("users").doc(user.uid).get().then(doc => {
            //console.log('admin',doc.data().admin)
            setAdmin(doc.data().admin);
        });
     }
     else{
        setAdmin(false);
     }

    }, [user]);
  
  
  return (
    <div className="memeContainer">
      <div className="memeContainer__title">
        <p>{title}</p>
      {admin &&
      <>
           <MoreVertIcon onClick={handleClick}/>
           <Menu
  id="simple-menu"
  anchorEl={anchorEl}
  keepMounted
  open={Boolean(anchorEl)}
  onClose={handleClose}
>
  <MenuItem onClick={handleClose}>Edit</MenuItem>
  <MenuItem onClick={handleClose}>Delete</MenuItem>
</Menu>
</>
            }
      </div>
      <div className="memeContainer__info">
        <div className="memeContainer__userInfo">by: {username}</div>
        <div className="memeContainer__timestamp">
        <Moment fromNow>{timestamp}</Moment>
          </div>
      </div>
      {memeDesc !== "" &&
      <div className="memeContainer__desc">
        {memeDesc}
      </div>
      }
      <Link to={`/meme/${memeId}`}> 
      <div className="memeContainer__meme">
        <img src={memeImage} />
      </div>
      </Link>
      <div className="memeContainer__controls">
        <Link to={`/meme/${memeId}`}> <ChatBubbleOutlineOutlinedIcon /></Link>
        <ThumbUpAltOutlinedIcon className={like && "likeSelected"} 
         onClick={user ? likeMeme : loginButtonOpen}
         />
        <div className="likePercentage">
          <span className="likeness">{likeCount} Likes</span>
          <span className="likesCount">({memeComments}) Comments</span>
        </div>
        <FavoriteBorderIcon className={unlike && "likeSelected"} 
        //  onClick={user ? favoritrMeme : loginButtonOpen}
        />
        <Popper open={openShare} anchorEl={anchorElShare} placement={placementShare}>
            <Paper>
              <FacebookShareButton />
            </Paper>
        </Popper>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={openCopied}
          onClose={handleCloseCopied}
          message="Meme Link Copied!!!"
        />
        <CopyToClipboard 
        text={`https://memewarsusa.com/meme/${memeId}`}
           onCopy={copied}
          >
          <ShareOutlinedIcon />
        </CopyToClipboard>
        {/* <ShareOutlinedIcon onClick={handleClickShare} /> */}
      </div>
    </div>
  );
}

export default MemeContainer;
