import React, {useState} from 'react'
import './MemeUpload.css'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable'; 
import { Button } from '@material-ui/core'
import CreateIcon from "@material-ui/icons/Create"
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { db, storage } from './firebase';
import firebase from 'firebase';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton'

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

function PaperComponent(props) {
    return (
      <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
  }

function MemeUpload() {
    const [open, setOpen] = useState(false);
    const [image, setImage] = useState(false);
    const [memeTitle, setmemeTitle] = useState("");
    const [memeDesc, setmemeDesc] = useState("");
    const [file, setFile] = useState("");
    const [progress, setProgress] = useState(0);

    const handleClickOpen = () => {
        setOpen(true);
      };
    
    const handleClose = () => {
        setOpen(false);
        setFile("");
        setmemeTitle("");
      };

    const handleChange = e => {
        if(e.target.files[0]) {
            setImage(e.target.files[0]);
            setFile(URL.createObjectURL(e.target.files[0]));
        }
    };

    const handleUpload = async () => {
        const uploadTask = storage.ref(`images/${image.name}`).put(image);
        
        await new Promise(resolve => {
        uploadTask.on(
            "state_changed",
            snapshot => {
              let calculateProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setProgress(calculateProgress);
             // console.log(progress);
             // console.log("snapshot:",calculateProgress);
            },
            error => {
                console.log(error);
            },
            async() => {
               await storage
                    .ref("images")
                    .child(image.name)
                    .getDownloadURL()
                .then(url => {
                   // console.log(url);
                    db.collection('memes')
                    .add({
                        memeTitle: memeTitle,
                        memeDesc: memeDesc,
                        memeLike: 0,
                        memeUrl: url,
                        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    });
                    setFile("");
                    setmemeTitle("");
                    setProgress(0);
                    handleClose();
                });           }
        );
              });
        
    }


    console.log("image", image)

    return (
        <>
        <div className="memeUpload">
            <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle color="primary" style={{ cursor: 'move' }} id="draggable-dialog-title">
          Upload a Meme
        </DialogTitle>
        <DialogContent>
        <TextField
          id="standard-full-width"
          className="memeTitle"
          label="Meme Title:"
          style={{ margin: 8 }}
          placeholder="Meme Title"
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          value={memeTitle}
          onChange={ e => setmemeTitle(e.target.value)}
        />
         <TextField
          className="memeDesc"
          label="Meme Description:"
          style={{ margin: 8 }}
          placeholder="Meme Description"
          margin="normal"
          multiline
          rowsMax={4}
          InputLabelProps={{
            shrink: true,
          }}
          value={memeDesc}
          onChange={ e => setmemeDesc(e.target.value)}
        />
        <div className="imageUploadPreview">
        {file ? (
          <img src={file} className="memeUpload__imagePreview"/>
        ) : (
          <Skeleton variant="rect" className="memeUpload__imagePreview" height={300} />
        )}
        
          <input
        accept="image/*"
        className="memeUpload__hidden"
        id="contained-button-file"
        multiple
        type="file"
        onChange={handleChange}
      />
        </div>
        
      <label htmlFor="contained-button-file">
        <Button variant="contained" color="primary" component="span">
          Upload a Meme Image...
        </Button>
      </label>
      {progress > 0 &&
      <LinearProgressWithLabel value={progress} />
      }
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpload} color="primary">
            Upload
          </Button>
        </DialogActions>
      </Dialog>
        </div>
        <div className="sidebar__header">
        <div className="sidebar__info">
        <Button className="sidebar__uploadButton" onClick={handleClickOpen}>
                Upload Meme
        <CreateIcon />
        </Button>
        </div>
    </div>
    </>
    )
}

export default MemeUpload
