import React, { useState, useEffect } from 'react'
import "./MemeDetails.css"
import { useStateValue } from './StateProvider';
import db from './firebase'
import { Link, useParams } from 'react-router-dom';
import MemeContainer from "./MemeContainer"
import { ContactSupportOutlined } from '@material-ui/icons';
import CommentSection from "./CommentSection"

function MemeDetails() {
    const { memeId } = useParams();
    const [{ user }] = useStateValue();
    const [meme, setMeme] = useState([]);
    const [refresh, setRefresh] = useState(0);
    useEffect(() => {
        if(memeId !== undefined){
            console.log(memeId);
        db.collection('memes').doc(memeId).get().then(doc => {

                let commentCount = 0;
                let likeCount = 0;
                if(doc.data().memeLike != undefined){
                    likeCount = doc.data().memeLike;
                }    
                
                if(doc.data().memeComments != undefined){
                    commentCount = doc.data().memeComments;
                }

            setMeme({
                'memeId': doc.id,
                'memeTitle': doc.data().memeTitle,
                'timestamp': doc.data().timestamp,
                'memeUrl': doc.data().memeUrl,
                'memeDesc': doc.data().memeDesc,
                'memeLike': likeCount,
                'memeComments': commentCount,
            });
        })
    }

    }, [memeId,refresh]);

    const refreshMemeDetails = () => {
    
        setRefresh(refresh + 1);
    }

    //console.log("meme",meme);

    return (
        <div className="memes">
            {meme?.memeId &&
            <>
                   <MemeContainer 
                    memeId={meme.memeId}
                    title={meme.memeTitle} 
                    username="meme wars" 
                    timestamp={meme.timestamp?.toDate()} 
                    memeImage={meme.memeUrl}
                    memeDesc={meme.memeDesc}
                    memeLike={meme.memeLike}
                    memeComments={meme.memeComments} />

                    {/* Comment Section */}
                    <CommentSection memeId={memeId} refreshMemeDetails={refreshMemeDetails} />
            </>
            }
        </div>
    )
}

export default MemeDetails
