import React from "react";
import "./RightSidebar.css";
import { FacebookProvider, Page } from "react-facebook";
import Iframe from 'react-iframe'

function RightSidebar() {

    const adsLinks = [
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=B08LMV3Q7F&asins=B08LMV3Q7F&linkId=b01b1cc97764f79054e7b164b79f2d20&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=B07VP78652&asins=B07VP78652&linkId=05d1c3c8e20108665a4f4300ae0f78af&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=B087Q82G7C&asins=B087Q82G7C&linkId=b5bc8819aa4807409c4d9303876654f4&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=B07VB9YGRS&asins=B07VB9YGRS&linkId=7cf46ec51c4f4d31f08b44a625589b2e&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=B01BX9Z3KS&asins=B01BX9Z3KS&linkId=0f7252f080340d61ab6c637a3882b154&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=0063029049&asins=0063029049&linkId=9057ff3b6c0136022d5b17d51f9bb881&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=1546059555&asins=1546059555&linkId=9cb84585a415160990903e59ddb0338c&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=1546077065&asins=1546077065&linkId=383d904481657860ef1c06aab488dbca&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=B07PJZQ8PQ&asins=B07PJZQ8PQ&linkId=2ff19beaffe57997ea80333e8f450418&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=B07H2QF8VZ&asins=B07H2QF8VZ&linkId=20af8276b965d0b1a60116935f4a45b0&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=B07HPHD4BX&asins=B07HPHD4BX&linkId=1a9d94f8276ad2de27b21999c99f3e18&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=B01IC15PKS&asins=B01IC15PKS&linkId=3b6840ea377439d6acb70dec62ea8998&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=B0882SXNDG&asins=B0882SXNDG&linkId=3494bde0f4b95ce3c14c3083ebaa8b84&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=B08KSV6M8G&asins=B08KSV6M8G&linkId=891e6027a0d50006dfbf40aba146f647&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=1684511348&asins=1684511348&linkId=21fadad6df55d56948afb0aadd3935af&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=B07BDKN9NL&asins=B07BDKN9NL&linkId=d09c1e2d6af9dadcb23634a866f3e97f&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=1455530441&asins=1455530441&linkId=196d59f0f0ab23e4d6182150aaaf4a2b&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=0807123668&asins=0807123668&linkId=09e0026ad51f39d3f499d4c10666177e&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=B076P9RFDS&asins=B076P9RFDS&linkId=787dd510d84aaf31971bbbc00429e383&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=B0859YFW8W&asins=B0859YFW8W&linkId=36d47b8d85bbcfa3dd472ca918743e1c&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=B07X987JWJ&asins=B07X987JWJ&linkId=1a26abddbbad404f51fa0b3c1c6c9843&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=B07S6KSH5S&asins=B07S6KSH5S&linkId=cc9db9a18c91e9dba30545528fce893f&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=B083BWZ8H5&asins=B083BWZ8H5&linkId=18c3af5005fc0424d94bad02abd4f433&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=B084NM9FMR&asins=B084NM9FMR&linkId=6592845d75827bb22b2921e5170b9f61&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=006289790X&asins=006289790X&linkId=5529191a1ece7634fc90e27e9664522c&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=B086QHY83B&asins=B086QHY83B&linkId=1198faf8df8a733aee11cb8fbaa00a78&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=B08KJ567XP&asins=B08KJ567XP&linkId=707e369a3b3806afb516934856360b9c&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=B08HSG16J7&asins=B08HSG16J7&linkId=b9c08749bda9201b2811ef552868db65&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=109406906X&asins=109406906X&linkId=4ad5583fcfa6e4b5c6da68e6321912be&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=B086TPG5B8&asins=B086TPG5B8&linkId=6af31ca080a62c20d97f37ec3450259e&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=B01N5GC6NL&asins=B01N5GC6NL&linkId=6aa2bfef23b9bfa8bf8380679845d604&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=B00V8X7XTO&asins=B00V8X7XTO&linkId=af41d88d2603352b2a581b5a4e66d01d&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=B07DLH9FR8&asins=B07DLH9FR8&linkId=ee1ce1bdb9bfd201c07d209878d2f87c&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=B089TYKDH6&asins=B089TYKDH6&linkId=f501f22be899f2cc459bf27321e7dce2&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=1521531218&asins=1521531218&linkId=0f2edbc83277b004e69a23f2e6813d40&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=1541619684&asins=1541619684&linkId=333e85695ad63e2e30e8db9832cd0d5c&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=0451524934&asins=0451524934&linkId=3c7568e49bd209323e6daf5c74f6c450&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=1460285646&asins=1460285646&linkId=3feaf2861ee48cfaf6fef5259b749da9&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=9527303451&asins=9527303451&linkId=012f35289d92715e47ec635c786303e9&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=B085HDHGP9&asins=B085HDHGP9&linkId=9a340192bb3f0e184ab53576f071fff4&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=B074ZLSDKQ&asins=B074ZLSDKQ&linkId=e46705c0d6f7c43c5363efd7ed3ef4d9&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=B0769ZGTVD&asins=B0769ZGTVD&linkId=8f22c9171b757bda6a8354055e1c34f1&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=B07Z17VK3G&asins=B07Z17VK3G&linkId=060391a79d74b0a3535e2fcb1afff0d4&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=B0778XC2C6&asins=B0778XC2C6&linkId=ae0fc810746e1e38fdce292cec280d3d&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=B00510ZX4M&asins=B00510ZX4M&linkId=a0a75387dfe6215d4baa4a056aec3abb&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=0143109936&asins=0143109936&linkId=6042de2efbc3e41f9e65b805c164bed3&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=0999189417&asins=0999189417&linkId=cfdfea64e792bae094bc30f5aa13bf22&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=B08JYC6YHN&asins=B08JYC6YHN&linkId=fd81cb21396a63b474afd074224451d6&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},   
    ];

    //console.log(adsLinks);
  return (
    <div className="rightSidebar">
      <div className="rightSidebar__FBPage">
        <FacebookProvider appId="784306979050130">
          <Page href="https://www.facebook.com/MemeWarsUSA/" tabs="timeline" />
        </FacebookProvider>
      </div>
      <div className="rightSidebar__ads">
        {/* <iframe
          marginwidth="0"
          marginheight="0"
          scrolling="no"
          frameborder="0"
          src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=B08GLJ3BQ3&asins=B08GLJ3BQ3&linkId=9bef6b94a09b86d67ce11684e3acaefb&show_border=false&link_opens_in_new_window=false&price_color=333333&title_color=0066C0&bg_color=FFFFFF"
        ></iframe> */}
        {adsLinks
            .map(({url}) => (
                <Iframe url={url}
                width="120px"
                id="myId"
                className="myClassname"
                height="240px"
                marginwidth="0"
              marginheight="0"
              scrolling="no"
              frameborder="0"
                styles={{width: "25px"}}/>
                ))}
        
      </div>
    </div>
  );
}

export default RightSidebar;
