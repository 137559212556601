import React,{useState} from 'react';
import './Header.css'
import { Avatar }  from '@material-ui/core';
import AccessTimeIcon from "@material-ui/icons/AccessTime"
import SearchIcon from "@material-ui/icons/Search"
import HelpIcon from "@material-ui/icons/HelpOutline"
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { useStateValue } from './StateProvider';
import { auth } from './firebase'
import {Link, useHistory} from 'react-router-dom'
import Login from './Login';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

function Header() {

    

    const [{user,loginScreen,userDetails}, dispatch] = useStateValue();
    const [anchorElHMenu, setAnchorElHMenu] = useState(null);
    const [openHMenu, setOpenHMenu] = useState(false);
    const history = useHistory();

  const handleClick = (event) => {
    setAnchorElHMenu(event.currentTarget);
    setOpenHMenu(true);
  };

  const handleCloseMenu = () => {
    //setAnchorElHMenu(null);
    setOpenHMenu(false);
  };
    //console.log(user);

    const logout = () => {
        if(user){
          auth.signOut();
          dispatch({
            type: "SET_USER",
            user: null
          });
        }
      }

    //const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        //setOpen(true);
        handleCloseMenu();
        dispatch({
          type: "SET_LOGIN_SCREEN",
          loginScreen: true
        });
    };

    const handleClose = () => {
        //setOpen(false);
        dispatch({
          type: "SET_LOGIN_SCREEN",
          loginScreen: false
        });
    };

    const goToProfile = () => {
      handleCloseMenu();
      history.push("/account");
    }

    return (
        <div className="header">
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={loginScreen}>
      <Login handleClose={handleClose}/>
    </Dialog>
            <div className="header__container">
            <div className="header__left">
                <Link to="/"><h2 className="header__siteName">Meme Wars USA</h2></Link>
            </div>
            <div className="header__search">
                {/* <SearchIcon />
                <input placeholder="Search" /> */}
            </div>
            <div className="header__right">
                <HelpIcon />
                {user ?
                <>
                <Avatar 
                className="header__avatar"
                alt={userDetails?.displayName}
                src={userDetails?.photoURL}
                onClick={handleClick}
            /> 
            {openHMenu &&
            <Menu
              className="header__menuProfile"
              anchorEl={anchorElHMenu}
              keepMounted
              open={openHMenu}
              onClose={handleCloseMenu}
            >
        <MenuItem onClick={goToProfile}>Profile</MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
}
            </>
                 : 
                
                 <Button
        variant="contained"
        color="primary"
        size="small"
        //className={classes.button}
        startIcon={<PowerSettingsNewIcon />} 
        onClick={handleClickOpen}
      >
        Login
      </Button>
                }
            </div>
            </div>
        </div>
    )
}

export default Header
