import { Paper } from '@material-ui/core'
import React, { useEffect } from 'react'
import "./VerifyEmail.css"
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Button from '@material-ui/core/Button'
import db, { auth, provider, fbprovider } from "./firebase";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import {useStateValue} from "./StateProvider"
import {useHistory} from "react-router-dom"


function VerifyEmail() {
  const [open, setOpen] = React.useState(false);
  const [{user}, dispatch] = useStateValue();
  const history = useHistory();
    //console.log(user);

    //Check If Verified Then redirect every 10 sec
    useEffect(() => {
        if(user.emailVerified){
            history.push("/");
        }
    },10000)


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    
  };

  const resendVerification = () => {
    auth.currentUser.sendEmailVerification({
        url: 'https://memewarsusa.com/',
      }).then(function() {
        setOpen(true);
      }).catch(function(error) {
        console.log(error)
      });
  }

    return (
        <div className="verify__email">
            <Snackbar open={open} autoHideDuration={1000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                Verification Email Resent.
                </Alert>
            </Snackbar>
            <Paper elevation={3}>
                <CheckCircleOutlineIcon />
                <h1>Verify your Email</h1>
                <h2>Go to your email inbox and please verify your email.</h2>
                {!open &&
                <Button variant="contained" color="primary" onClick={resendVerification}>
                    Resend Verification Email.
                </Button>
                }
            </Paper>
        </div>
    )
}

export default VerifyEmail
