export const initialState = {
    user: null,
    userDetails: null,
    loginScreen: false,
    activeReply: "",
    refreshUser: 0,
};

export const actionTypes = {
    SET_USER: "SET_USER",
    SET_LOGIN_SCREEN: "SET_LOGIN_SCREEN",
    SET_ACTIVE_REPLY: "SET_ACTIVE_REPLY",
    SET_REFRESH_USER: "SET_REFRESH_USER",
    SET_USER_DETAILS: "SET_USER_DETAILS",
};

const reducer = (state, action) => {
    //console.log(action);

    switch(action.type) {
        case actionTypes.SET_USER:
         return {
             ...state,
             user: action.user
         }
         case actionTypes.SET_USER_DETAILS:
            return {
                ...state,
                userDetails: action.userDetails
            }
        case actionTypes.SET_LOGIN_SCREEN:
         return {
             ...state,
             loginScreen: action.loginScreen
         }
         case actionTypes.SET_ACTIVE_REPLY:
         return {
                ...state,
                activeReply: action.activeReply
         }
         case actionTypes.SET_REFRESH_USER:
         return {
                ...state,
                refreshUser: action.refreshUser
         }
        default:
            return state;
    }
}


export default reducer;