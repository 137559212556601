import { Avatar, Button, Card, CardContent, IconButton } from '@material-ui/core';
import React,{useState, useEffect} from 'react'
import {useStateValue} from "./StateProvider"
import "./AccountManagement.css"
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable'; 
import CreateIcon from "@material-ui/icons/Create"
import TextField from '@material-ui/core/TextField';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { db, storage } from './firebase';
import firebase from 'firebase';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton'

function LinearProgressWithLabel(props) {
    return (
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
  
  function PaperComponent(props) {
      return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
          <Paper {...props} />
        </Draggable>
      );
    }

function AccountManagement() {
    const [{user, refreshUser, userDetails}, dispatch] = useStateValue();

    const [open, setOpen] = useState(false);
    const [image, setImage] = useState(false);
    const [displayName, setDisplayName] = useState(userDetails?.displayName);
    const [memeDesc, setmemeDesc] = useState("");
    const [file, setFile] = useState("");
    const [progress, setProgress] = useState(0);

    const handleClickOpen = () => {
        setOpen(true);
      };
    
    const handleClose = () => {
        setOpen(false);
        setFile("");
      };

    const handleChange = e => {
        if(e.target.files[0]) {
            setImage(e.target.files[0]);
            setFile(URL.createObjectURL(e.target.files[0]));
        }
    };

    const handleUpload = async () => {
        
      if(image){
      const uploadTask = storage.ref(`images/${image.name}`).put(image);
        
        await new Promise(resolve => {
        uploadTask.on(
            "state_changed",
            snapshot => {
              let calculateProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setProgress(calculateProgress);
             // console.log(progress);
             // console.log("snapshot:",calculateProgress);
            },
            error => {
                console.log(error);
            },
            async() => {
               await storage
                    .ref("images")
                    .child(image.name)
                    .getDownloadURL()
                .then(url => {
                   // console.log(url);
                    db.collection('users').doc(user?.uid)
                    .update({
                        displayName: displayName,
                        photoURL: url,
                    });
                    setFile("");
                    setProgress(0);
                    handleClose();
                    let refreshCounter = refreshUser + 1;
                    dispatch({
                      type: "SET_REFRESH_USER",
                      refreshUser: refreshCounter,
                    });
            
                });           }
        );
              });
        }else{

         await db.collection('users').doc(user?.uid)
                    .update({
                        displayName: displayName,
                        photoURL: userDetails?.photoURL,
                    }).then(function() {
                    setFile("");
                    setProgress(0);
                    handleClose();
                    let refreshCounter = refreshUser + 1;
                    dispatch({
                      type: "SET_REFRESH_USER",
                      refreshUser: refreshCounter,
                    });
                    console.log("refreshUser",refreshUser)
                  });
        }

        
    }
    useEffect(() => {
      if(userDetails){
        setDisplayName(userDetails?.displayName);
      }
    }, [userDetails])
    

// console.log("user",user);
// console.log("userdetails",userDetails);
    return (
        <div className="account__profile">
            <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle color="primary" style={{ cursor: 'move' }} id="draggable-dialog-title">
          Update User Profile
        </DialogTitle>
        <DialogContent>
        <div className="Profile">
        {file ? (
          <Avatar 
          className="account__profileavatar"
          alt={userDetails?.displayName}
          src={file}
          />
        ) : (
            <Avatar 
            className="account__profileavatar"
            alt={userDetails?.displayName}
            src={userDetails?.photoURL}
            />
        )}
        
        <input
        accept="image/*"
        className="memeUpload__hidden"
        id="contained-button-file"
        multiple
        type="file"
        onChange={handleChange}
      />
        </div>
        
      <label htmlFor="contained-button-file">
        <Button variant="contained" color="primary" component="span">
          Upload Profile Image...
        </Button>
      </label>
      {progress > 0 &&
      <LinearProgressWithLabel value={progress} />
      }
      <TextField
          id="standard-full-width"
          className="memeTitle"
          style={{ margin:20}}
          placeholder="Display Name:"
          margin="normal"
          label={`Display Name:`}
          value={displayName}
          onChange={ e => setDisplayName(e.target.value)}
        />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpload} color="primary">
            Upload
          </Button>
        </DialogActions>
      </Dialog>
            <Card className="account__profileCard">
                <CardContent>
                    <div>
                <Avatar 
                className="account__profileavatar"
                alt={userDetails?.displayName}
                src={userDetails?.photoURL}
                />
                <Button
                className="account__profileAvatarChange"
                onClick={handleClickOpen}
                >
                    Change Profile Picture
                </Button>
                <div className="account__profileDetails">
                <h2>Hello, {userDetails?.displayName} <IconButton onClick={handleClickOpen}><EditIcon /></IconButton></h2>
                <h2 className="normalFont">{user?.email}</h2>
                </div>
                </div>
                <hr />
                <div className="account__stats">
                    <h2>Statistics</h2>
                    <div className="account__statsGrid">
                        <Card>
                            <CardContent>

                            </CardContent>
                        </Card>
                        <Card>
                            <CardContent>
                                
                            </CardContent>
                        </Card>
                        <Card>
                            <CardContent>
                                
                            </CardContent>
                        </Card>
                        <Card>
                            <CardContent>
                                
                            </CardContent>
                        </Card>
                        <Card>
                            <CardContent>
                                
                            </CardContent>
                        </Card>
                        <Card>
                            <CardContent>
                                
                            </CardContent>
                        </Card>
                        <Card>
                            <CardContent>
                                
                            </CardContent>
                        </Card>
                        <Card>
                            <CardContent>
                                
                            </CardContent>
                        </Card>
                    </div>
                </div>
                </CardContent>
            </Card>
        </div>
    )
}

export default AccountManagement
