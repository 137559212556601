import React, {useState, useEffect} from 'react'
import './Sidebar.css'
import CreateIcon from "@material-ui/icons/Create"
import SidebarOption from './SidebarOption'
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import BurstModeIcon from '@material-ui/icons/BurstMode';
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ChatIcon from '@material-ui/icons/Chat';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable'; 
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';

import db from './firebase'
import { useStateValue } from './StateProvider';
import { Button } from '@material-ui/core'
import MemeUpload from './MemeUpload'
import { Link } from 'react-router-dom'



function Sidebar({orderBy}) {
    const [{ user }] = useStateValue();
    const [admin, setAdmin] = useState(false);


    useEffect(() => {

    if(user?.uid){
        db.collection("users").doc(user.uid).get().then(doc => {
            //console.log('admin',doc.data().admin)
            setAdmin(doc.data().admin);
        });
     }
     else{
        setAdmin(false);
     }

    }, [user]);

    return (
        <div className="sidebar">
            {admin &&
           <MemeUpload />
            }
            
            <div className="sidebar__channels">
                <div className="sidebar__options">
                <SidebarOption Icon={BurstModeIcon} title="Latest Memes" activeOption={orderBy === "Latest Memes" ? true : false} />
                <SidebarOption Icon={StarBorderOutlinedIcon} title="Top Memes" activeOption={orderBy === "Top Memes" ? true : false}/>
                <SidebarOption Icon={ShuffleIcon} title="Random Memes" activeOption={orderBy === "Random Memes" ? true : false}/>
                
                {user &&
                <>
                {/* <SidebarOption Icon={SubscriptionsIcon} title="My Subscriptions" /> */}
                <SidebarOption Icon={FavoriteBorderIcon} title="Favorites" activeOption={orderBy === "Favorites" ? true : false}/>
                {/* <SidebarOption Icon={SettingsApplicationsIcon} title="Moderate" />
                <SidebarOption Icon={CloudUploadIcon} title="My Uploads" />
                <SidebarOption Icon={ChatIcon} title="Chat" />
                <SidebarOption Icon={EqualizerIcon} title="Ranking" /> */}
                </>
                }
                <a href="https://memewarsusa.news/" target="_blank"><SidebarOption Icon={VideoLibraryIcon} title="News / Videos"/></a>
                </div>
                
                <hr />
                <p className="sidebar__copyright">© 2020 MemeWarsUSA</p>
                


            </div>
        </div>
    )
}

export default Sidebar
