import firebase from "firebase"

const firebaseConfig = {
  apiKey: "AIzaSyB2RQB91fsd-Vjp_zwVqdSPEL6w2k-FBE8",
  authDomain: "memes-f8edf.firebaseapp.com",
  databaseURL: "https://memes-f8edf.firebaseio.com",
  projectId: "memes-f8edf",
  storageBucket: "memes-f8edf.appspot.com",
  messagingSenderId: "953950858235",
  appId: "1:953950858235:web:db89948ff29a87dda2f95e",
  measurementId: "G-XTHMW0XLQB"
};

  const firebaseApp = firebase.initializeApp(firebaseConfig);

  const db = firebaseApp.firestore();
  const increment = firebase.firestore.FieldValue.increment(1);
  const decrement = firebase.firestore.FieldValue.increment(-1);
  const auth = firebase.auth();
  const provider = new firebase.auth.GoogleAuthProvider();
  const fbprovider = new firebase.auth.FacebookAuthProvider();
  const storage = firebase.storage();

  export { db, auth, provider, fbprovider, storage, increment, decrement };
  export default db; 