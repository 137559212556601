import React,{useState,useEffect} from "react";
import "./CommentSection.css";
import CommentInput from "./CommentInput";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import CommentContainer from "./CommentContainer";
import db from "./firebase"

function CommentSection({ memeId, refreshMemeDetails }) {

    const [comments, setComments] = useState([]);
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {

        let commentsRef = db.collection('comments');
        commentsRef
        .where("memeId","==",memeId)
        //.where("reply","==",false)
        .get()
        .then(
            (snapshot) => {
             
            setComments(
                        snapshot.docs
                        .sort((a, b) => a.data().timestamp?.toDate().valueOf() - b.data().timestamp?.toDate().valueOf())
                        .map((doc) => {
                        
                        let replies = [];
                        let repliesRef = db.collection(doc.ref.path + '/comments');
                            repliesRef
                            .get()
                            .then(function(querySnapshot) {

                              querySnapshot.docs
                        .sort((a, b) => a.data().timestamp?.toDate().valueOf() - b.data().timestamp?.toDate().valueOf())
                        .map((doc) => { 
                          replies.push({
                            'commentId': doc.id,
                            'userId': doc.data().userId,
                            'timestamp': doc.data().timestamp,
                            'comment': doc.data().commentContent,
                            'commentImageUrl': doc.data().commentImageUrl,
    
                        })

                        });
                              
                        
                              
                            })
                            .catch(function(error) {
                                console.log("Error getting documents: ", error);
                            });

                            

                        return ({
                            'commentId': doc.id,
                            'userId': doc.data().userId,
                            'timestamp': doc.data().timestamp,
                            'comment': doc.data().commentContent,
                            'commentImageUrl': doc.data().commentImageUrl,
                            'replies': replies,
                        })
                      }
                        ))
                      })
      .catch(function(error) {
          console.log("Error getting documents: ", error);
      });
            

      

    }, [refresh]);
    
    const refreshFunc = () => {
    
        setRefresh(refresh + 1);

        if(refreshMemeDetails != undefined){
          refreshMemeDetails();
        }
    }


  return (
    <div className="commentSection">
      <div className="commentSection__Comments">
          <Card>
              <CardHeader
               title="Comments"
               />
              <CardContent>
            {
              comments
              .map(({commentId, userId, timestamp, comment, commentImageUrl, replies}) => (
                <CommentContainer 
                    commentId={commentId} 
                    userId={userId} 
                    timestamp={timestamp?.toDate()} 
                    comment={comment}
                    commentImageUrl={commentImageUrl}
                    memeId={memeId}
                    refreshFunc={refreshFunc}
                    replies={replies}
                 />
              ))
            }
            <CommentInput memeId={memeId} refreshFunc={refreshFunc}/>
              </CardContent>
          </Card>
      </div>
    </div>
  );
}

export default CommentSection;
