import React,{useState} from 'react'
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {db,storage, increment} from './firebase'
import { useStateValue } from './StateProvider';
import firebase from 'firebase';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import Avatar from '@material-ui/core/Avatar'


function CommentInput({memeId,refreshFunc,replyTo}) {
    const [comment, setComment] = useState("");
    const [{ user, loginScreen, activeReply, userDetails }, dispatch] = useStateValue();
    const [image, setImage] = useState(false);
    const [file, setFile] = useState("");
    const [progress, setProgress] = useState(0);
    const [reply, setReply] = useState(
      replyTo ? true : false
    );

    

    const handleChange = e => {
      if(e.target.files[0]) {
          setImage(e.target.files[0]);
          setFile(URL.createObjectURL(e.target.files[0]));
      }
    };

  const handleComment = async (e) => {
   const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp()
   const commentMemeBatch = db.batch();
   let commentsRef = db.collection('comments').doc();
   const memeRef = db.collection('memes').doc(memeId);
  
   if(reply){
    commentsRef = db.collection('comments').doc(replyTo).collection('comments').doc();
   }else{
     replyTo = "";
   }
   

    if(image){
      const uploadTask = storage.ref(`images/${image.name}`).put(image);
    await new Promise(resolve => {
    uploadTask.on(
        "state_changed",
        snapshot => {
          let calculateProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(calculateProgress);
          //console.log(progress);
          //console.log("snapshot:",calculateProgress);
        },
        error => {
            console.log(error);
        },
        async() => {
           await storage
                .ref("images")
                .child(image.name)
                .getDownloadURL()
            .then( async (url) => {
                console.log(url);
                commentMemeBatch.set(commentsRef,{
                    commentContent: comment,
                    commentImageUrl: url,
                    memeId: memeId,
                    reply: reply,
                    replyTo: replyTo,
                    timestamp: serverTimestamp,
                    userId: user.uid,
                });
                commentMemeBatch.set(memeRef, { memeComments:increment },{merge: true})
                
              await commentMemeBatch.commit().then(function() {
                setComment("");
                setFile("");
                setProgress(0);
                refreshFunc();
                removeReply();
              })
              .catch(function(error) {
                  console.error("Error writing document: ", error);
              });
                
                
            });           }
    );
          });

        }else{
          commentMemeBatch.set(commentsRef,{
            commentContent: comment,
            memeId: memeId,
            reply: reply,
            replyTo: replyTo,
            timestamp: serverTimestamp,
            userId: user.uid,
        });
        commentMemeBatch.set(memeRef, { memeComments:increment },{merge: true})
        
      await commentMemeBatch.commit().then(function() {
        setComment("");
        setFile("");
        setProgress(0);
        refreshFunc();
        removeReply();
      })
      .catch(function(error) {
          console.error("Error writing document: ", error);
      });
        }
}

    // const handleComment = (e) => {
    //     db.collection('comments')
    //     .add({
    //         commentContent: comment,
    //         memeId: memeId,
    //         reply: false,
    //         replyTo: "",
    //         timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    //         userId: user.uid,
    //     });
    //     setComment("");
    //     refreshFunc();
    // }

    const handleOpenLogin = () => {
      //setOpen(true);
      dispatch({
        type: "SET_LOGIN_SCREEN",
        loginScreen: true
      });
  };

  const removeReply = () => {
    //setOpen(true);
    dispatch({
      type: "SET_ACTIVE_REPLY",
      activeReply: ""
    });
};

    return (
        <div className="commentInput">
        {user ?
        <>
        {file &&
            <img src={file} className="memeUpload__imagePreview"/>
            }
        <form className="commentSection__Form" 
        onSubmit={(e) => {                                       
          e.preventDefault();                                       
          handleComment(e);                               
            }}    
        >
        <Avatar 
                alt={userDetails?.displayName}
                src={userDetails?.photoURL}
            /> 
          <div className="commentSection__FormTextArea">
            {/* <TextField
              id="outlined-textarea"
              placeholder="Comment This Meme..."
              multiline
              variant="outlined"
              rows={4}
              value={comment}
              onChange={ e => setComment(e.target.value)}
            /> */}
            <FormControl 
            // className={clsx(classes.margin, classes.textField)}
            >
          <InputLabel htmlFor="standard-adornment-password">Write a comment...</InputLabel>
          <Input
            id="standard-adornment-password"
            // type={values.showPassword ? 'text' : 'password'}
            value={comment}
              onChange={ e => setComment(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                 <input
                    accept="image/*"
                    className="memeUpload__hidden"
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={handleChange}
                  />
                <label htmlFor="contained-button-file">
                <IconButton
                 variant="contained" color="primary" component="span"
                >
                <CameraAltIcon />
                  {/* {values.showPassword ? <Visibility /> : <VisibilityOff />} */}
                </IconButton>
                </label>
              </InputAdornment>
            }
          />
        </FormControl>
     
     
          </div>
          <button className="hideButton" type="submit"></button>
        </form>
            </>
        :
          <p onClick={handleOpenLogin} className="noLogin">Log In to Comment.</p>
        }
      </div>
    )
}

export default CommentInput
