import React,{useState,useEffect} from 'react'
import "./CommentContainer.css"
import { useStateValue } from './StateProvider';
import Avatar from '@material-ui/core/Avatar'
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import db from './firebase'
import CommentInput from "./CommentInput";


function CommentContainer({commentId,userId,timestamp,comment,commentImageUrl,memeId,refreshFunc,replies,reply}) {

    const [{ user, activeReply}, dispatch] = useStateValue();
    const [commentUser, setCommentUser] = useState([]);


    useEffect(() => {
        if(userId !== undefined){
            //console.log(userId);
        db.collection('users').doc(userId).get().then(doc => {
            setCommentUser({
                'userId':doc.id,
                'displayName': doc.data()?.displayName,
                'photoURL': doc.data()?.photoURL,
            });
        })
    }
    }, [replies,]); 

const setActiveReplyComment = (commentId) => {
    dispatch({
        type: "SET_ACTIVE_REPLY",
        activeReply: commentId
      });
}
    
    return (
        <div className="commentContainer">
            <div className="commentContainer__avatar">
            <Avatar 
                alt={commentUser?.displayName}
                src={commentUser?.photoURL}
            /> 
            </div>
           
            <div className="commentContainer__details">
                <div className="commentContainer__username">{commentUser?.displayName} <Moment fromNow>{timestamp}</Moment></div>
                {commentImageUrl &&
                <div className="commentContainer__commentImage"><img src={commentImageUrl}/></div>
                }
                <div className="commentContainer__comment">{comment}</div>
                
                {replies &&
                <div className="commentContainer__replyDetails">
            {
              replies
              .map(({commentId, userId, timestamp, comment, commentImageUrl}) => (
                <CommentContainer 
                    commentId={commentId} 
                    userId={userId} 
                    timestamp={timestamp?.toDate()} 
                    comment={comment}
                    commentImageUrl={commentImageUrl}
                    memeId={memeId}
                    refreshFunc={refreshFunc}
                    reply={true}
                 />
              ))
            }
                </div>
}
                {user && !reply  &&
                <div className="commentContainer__reply">
                {activeReply != commentId ?
                <Link 
                onClick={() => {setActiveReplyComment(commentId)}}
                >Reply</Link>
                :
                <CommentInput 
                memeId={memeId} 
                replyTo={commentId}
                refreshFunc={refreshFunc}
                />
                }
                </div>
                }
                
            </div>
        </div>
    )
}

export default CommentContainer
