import React, { useState, useEffect } from 'react'
import './Memes.css'
import MemeContainer from './MemeContainer'
import db from './firebase'
import { useStateValue } from './StateProvider';
import { Link } from 'react-router-dom';
import Pagination from '@material-ui/lab/Pagination';
import Iframe from 'react-iframe'


function Memes({orderBy}) {
    const [{ user }] = useStateValue();
    let [memes, setMemes] = useState([]);
    const [orderByState, setOrderByState] = useState(orderBy);
    let pageLoad = 0;
    useEffect(() => {
        pageLoad = 1;
        setPage(1);
        window.scrollTo(0,0);
        let memesCollection;

        if(orderBy == 'Latest Memes'){
            memesCollection =  db.collection('memes')
            .orderBy('timestamp','desc')
        }
        else if(orderBy == 'Top Memes')
        {
            memesCollection =  db.collection('memes')
            .orderBy('memeLike','desc')
        }
        else{
            memesCollection =  db.collection('memes')
            .orderBy('timestamp','desc')
        }

       
        // console.log(lastVisible);
        if(orderBy == 'Top Memes' || orderBy == 'Random Memes'){
            memesCollection.get()
            .then(function(querySnapshot) {
                let docs = querySnapshot.docs;
                if(orderBy == 'Random Memes')
                {
                    docs = querySnapshot.docs
                    .map((a) => ({sort: Math.random(), value: a}))
                    .sort((a, b) => a.sort - b.sort)
                    .map((a) => a.value);
                }

                setMemes(
                    docs.map((doc) => { 
                    // doc.data() is never undefined for query doc snapshots
                    //console.log(doc.id, " => ", doc.data());
                    let commentCount = 0;
                    let likeCount = 0;
                    if(doc.data().memeLike != undefined){
                        likeCount = doc.data().memeLike;
                    }    
                    
                    if(doc.data().memeComments != undefined){
                            commentCount = doc.data().memeComments;
                    }
                    
                    return ({
                        'memeId': doc.id,
                        'memeTitle': doc.data().memeTitle,
                        'timestamp': doc.data().timestamp,
                        'memeUrl': doc.data().memeUrl,
                        'memeDesc': doc.data().memeDesc,
                        'memeLike': likeCount,
                        'memeComments': commentCount,
                    })
                })
                )
            })
            .catch(function(error) {
                console.log("Error getting documents: ", error);
            });
        }
        else{
        memesCollection.get()
        .then(function(snapshot) {
            setMemes(
                snapshot.docs.map((doc) => { 
                let commentCount = 0;
                let likeCount = 0;
                if(doc.data().memeLike != undefined){
                    likeCount = doc.data().memeLike;
                }    
                
                if(doc.data().memeComments != undefined){
                        commentCount = doc.data().memeComments;
                }
                
                return ({
                    'memeId': doc.id,
                    'memeTitle': doc.data().memeTitle,
                    'timestamp': doc.data().timestamp,
                    'memeUrl': doc.data().memeUrl,
                    'memeDesc': doc.data().memeDesc,
                    'memeLike': likeCount,
                    'memeComments': commentCount,
                })
            }
                )
            )
            
            
            }
        )
        }


    }, [orderBy]);


    //table pagination
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
      window.scrollTo(0,0);
    };
    
    
    

    //console.log("memes =>",memes)

    const adsLinks = [
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=B08LMV3Q7F&asins=B08LMV3Q7F&linkId=b01b1cc97764f79054e7b164b79f2d20&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
        {url: "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=memewarsusa-20&marketplace=amazon&region=US&placement=B07VP78652&asins=B07VP78652&linkId=05d1c3c8e20108665a4f4300ae0f78af&show_border=true&link_opens_in_new_window=true&price_color=333333&title_color=0066c0&bg_color=eb1717"},
    ]

    let adCounter = 0;
    return (
        <div className="memes">
            {orderBy === 'Latest Memes' &&
            <div className="memes__bannerImage">
                <img src="/memewarsbanner.png" />
                <div className="memes__newSocialImage">
                    <a href="https://mewe.com/p/memewarsusa"><img src="/mewelogo.jpg" /></a>
                    <a href="https://www.parler.com/profile/Memewarsusa/posts"><img src="/Parler-Vertical-Logo.wine.svg" /></a>
                </div>
            </div>
            }
            <div className="memes__filterInfo">{orderBy}</div>
            {/* Meme Container Component */}

            {memes
            .slice(page * 10 - 10, page * 10)
            .map(({memeId,memeTitle, memeUrl, timestamp, memeDesc , memeLike, memeComments}) => (
                <>   
                <MemeContainer 
                    memeId={memeId}
                    title={memeTitle} 
                    username="meme wars" 
                    timestamp={timestamp?.toDate()} 
                    memeImage={memeUrl}
                    memeDesc={memeDesc} 
                    memeLike={memeLike}
                    memeComments={memeComments}
                     />
                     {adCounter < 1 &&
                     <div className="adsContainer">
                     {adsLinks
                        .map(({url}) => (
                            <Iframe url={url}
                            width="120px"
                            id="myId"
                            className="myClassname"
                            height="240px"
                            marginwidth="0"
                          marginheight="0"
                          scrolling="no"
                          frameborder="0"
                            styles={{width: "25px"}}/>
                            ))
                            }
                            {adCounter = adCounter + 1}
                     </div>
                    }
                </>
                ))}
                <Pagination className="memesPagination" count={parseInt(memes.length / 10) + 1} page={page} 
                 onChange={handleChangePage} 
                />
              </div>
    )
}

export default Memes
